import React from "react";
import { useState } from "react";
import fb from "../Asset/Social/fb.png"
import insta from "../Asset/Social/insta.png"
// import wt from "../Asset/Social/wt.png"
import ink from "../Asset/Social/in.png"
import tw from "../Asset/Social/tw.png"
import yt from "../Asset/Social/yt.png"
import contact from "../Asset/con1.png"

import { useRef } from 'react';
import emailjs from '@emailjs/browser';

const Contact = () => {
  const form = useRef();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [massage, setMassage] = useState("");
  const [records, setRecords] = useState([]);
  const sendEmail = (e) => {
    e.preventDefault();
    setRecords([...records, { name, email, massage }]);
    setName("");
    setEmail("");
    setMassage("");
    emailjs.sendForm('service_rgqnbv6', 'template_cjvvxfl', form.current, '-TAGU3dCbAuM61JfH')
      .then((result) => {
          console.log(result.text);
          window.alert("Thanks for letting us know");
      }, (error) => {
          console.log(error.text);
          window.alert("Sorry we Can't store your info Please Reach us at[contact@c9productionhouse.com]")
      });
  };
  return (
    

<div class="container pt-10 items-center justify-between mx-auto xl:px-10">
 <section class="bg-white dark:bg-gray-900 xl:px-20 ">
    <div class="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
        <div class="mr-auto place-self-center lg:col-span-6">

<form class="px-6" ref={form} onSubmit={sendEmail} >
<h1 class="text-[#6C63FF] text-2xl xl:text-4xl xl:font-extrabold mb-4 text-3xl font-bold dark:text-white">Let’s Get Connected</h1>
            <p class="text-[#FFFFF] xl:font-normal font-normal  dark:text-white">If you're interested in more about our services, or have any questions or comments, please feel free to get in touch with us </p> <br></br>
            <div class="flex mt-4 space-x-4 sm:justify-left sm:mt-0">
            <a href="https://www.facebook.com/c9productionhouse/" target="_blank" class="text-gray-500 hover:text-gray-900 dark:hover:text-white">
            <img class="w-5 h-5 text-primary-600 lg:w-6 lg:h-6 dark:text-primary-300"  src={fb}/>
            </a>
            <a href="https://www.instagram.com/c9production/" target="_blank" class="text-gray-500 hover:text-gray-900 dark:hover:text-white">
            <img class="w-5 h-5 text-primary-600 lg:w-6 lg:h-6 dark:text-primary-300"  src={insta}/>
            </a>
            {/* <a href="whatsapp://send?text=Hello World!&phone=+918657584769" target="_blank" class="text-gray-500 hover:text-gray-900 dark:hover:text-white">
            <img class="w-5 h-5 text-primary-600 lg:w-6 lg:h-6 dark:text-primary-300"  src={wt}/>
            </a> */}
            <a href="https://in.linkedin.com/company/c9-production?trk=public_profile_topcard-current-company" target="_blank" class="text-gray-500 hover:text-gray-900 dark:hover:text-white">
            <img class="w-5 h-5 text-primary-600 lg:w-6 lg:h-6 dark:text-primary-300"  src={ink}/>
            </a>
            <a href="https://mobile.twitter.com/c9_house"  target="_blank" class="text-gray-500 hover:text-gray-900 dark:hover:text-white">
            <img class="w-5 h-5 text-primary-600 lg:w-6 lg:h-6 dark:text-primary-300"  src={tw}/>
            </a>
            <a href="https://www.youtube.com/@c9productionhouse468"  target="_blank" class="text-gray-500 hover:text-gray-900 dark:hover:text-white">
            <img class="w-5 h-5 text-primary-600 lg:w-6 lg:h-6 dark:text-primary-300"  src={yt}/>
            </a>
        </div> <br/>
  <div class="z-0 w-full mb-6 group">
        <input type="text" value={name}
          onChange={(e) => setName(e.target.value)} name="user_name" id="user_name" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder="Name" required />
    </div>
  <div class=" z-10 w-full mb-6 group">
        <input type="email"   value={email}
          onChange={(e) => setEmail(e.target.value)} name="user_email" id="user_email" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder="Email" required />
    </div>
  <div class="z-10 w-full mb-16 group">
        <input type="text" value={massage}
          onChange={(e) => setMassage(e.target.value)} name="message" id="message" class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder="Message" required />
    </div>
<div class="pt-[2px]">
<button type="submit" value="Send" class="text-white  bg-[#6C63FF] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-2xl  text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Submit</button>
</div>
  
</form>

        </div>
        <div class="lg:mt-0 lg:col-span-6 lg:flex xl:w-160 xl:h-100">
            <img src={contact} alt="mockup"/>
        </div>                
    </div>
</section> 

  

</div>);
};

export default Contact;
