
import React from 'react';
import { Link } from 'react-router-dom';
import close from '../Asset/close.png';

const MenuItems = ({showMenu,active}) => {
  return (
 
  <div>

<ul className={active ? 'flex-col flex items-center fixed inset-0 left-1/4 uppercase bg-black/40 backdrop-blur-xl gap-8 justify-center p-8 md:hidden' : 'hidden'}>
                    <button onClick={showMenu} className='scale-80 cursor-pointer text-black'><img src={close}class="w-12 h-12 text-primary-600 lg:w-6 lg:h-6 dark:text-primary-300" /></button> 

               <li><Link to='/' onClick={showMenu}>Home</Link></li>
                    <li><Link to='/Services' onClick={showMenu}>Services</Link></li>
                    <li><Link to='/About' onClick={showMenu}>About</Link></li>
                    <li><Link to='/Contact' onClick={showMenu}>Contact</Link></li>
                </ul>
  </div>
  );
};

export default MenuItems;
