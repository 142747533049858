import React from "react";

const Terms = () => {
  return (

<div class="container flex flex-wrap items-center justify-between mx-auto " >
<div class="grid max-w-screen-xl  lg:px-2 xl:px-24 px-12 sm:px-30 py-8 ">
      
      
<h1 class="max-w-2xl mb-4 text-4xl font-extrabold text-[#6C63FF] tracking-tight leading-none md:text-[29px] xl:text-4xl dark:text-white">Terms & Conditions</h1>
          

        <p>

Payment terms: This section outlines the payment terms for the agency's services, including the payment schedule, invoicing procedures, and late payment penalties.

Scope of work: This section defines the scope of the project and the specific deliverables that the agency will provide. It also outlines the client's responsibilities and expectations.

Intellectual property: This section outlines the ownership and use of intellectual property related to the project, including copyrights, trademarks, and other proprietary materials.

Confidentiality: This section outlines the terms of confidentiality and nondisclosure of information related to the project or the agency's business operations.

Termination: This section outlines the terms for terminating the contract, including notice requirements and any applicable fees or penalties.

Liability: This section outlines the liability of the agency and the client for any damages or losses related to the project or the agency's services.

Governing law and dispute resolution: This section outlines the governing law for the contract and the process for resolving any disputes or legal issues related to the project.

</p>
                     
    </div>

</div>


  );
};

export default Terms ;