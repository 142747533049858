import React from "react";
import a1 from '../Asset/1.png'
import a2 from '../Asset/2.png'
import a3 from '../Asset/3.png'
import h3 from '../Asset/hero14.svg'
import { useNavigate } from "react-router-dom";
import dm from "../Asset/Services_icon/dm.png"
import da from "../Asset/Services_icon/da.png"
import ph from "../Asset/Services_icon/ph.png"
import pd from "../Asset/Services_icon/pd.png"
import an from "../Asset/Services_icon/otaku.png"
import vi from "../Asset/Services_icon/video.png"
import hero2 from "../Asset/hero11.svg"
// import hero1 from "../Asset/hero13.svg"
import review from "../Asset/CustomerReviews.png"
import hero1 from "../Asset/c9hero1.png"

const Home = () => {
  let navigate = useNavigate(); 
  const Contact = () =>{ 
    let path = `/Contact`; 
    navigate(path);
  }
  const Work = () =>{ 
    let path = `/Works`; 
    navigate(path);
  }
  const map = () =>{ 
    let url= `https://www.google.com/maps/place/C9+Production+House/@16.6608505,74.2273656,15z/data=!3m1!4b1!4m6!3m5!1s0x3bc0ff838098a00f:0x45158109d8104ae1!8m2!3d16.6608513!4d74.2458196!16s%2Fg%2F11jtywfs95?entry=ttu
    `; 
    navigate(url);
  }
  
  return (
    

<div class="container items-center justify-between mx-auto xl:px-10">
<section class="bg-white dark:bg-gray-900 xl:px-20 px-8 lg:px-0 ">
    <div class="grid max-w-screen-xl lg:py-18 lg:px-2 px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
        <div class="mr-auto place-self-center lg:col-span-7  ">
            <h1 class="max-w-2xl  mb-4 text-4xl font-extrabold tracking-tight leading-none xl:mb-1 md:text-5xl sm:mb-6 text-[#6C63FF] xl:text-5xl dark:text-white">We believe in creativity </h1>
            <p class="max-w-2xl mb-2 font-normal text-gray-500 lg:mb-8 xl:mb-2 sm:mb-8 md:text-lg lg:text-xl xl:py-8 dark:text-gray-400">We believe that creativity is essential for success in today's world. It's what sets us apart from the competition and helps us stand out.It's a key part of success, and it's something we strive to foster in all aspects of our lives.

From branding to digital marketing, our team of experts is dedicated to delivering solutions that deliver measurable results.</p>

<button
 onClick={Contact}
  class="middle none  center rounded-lg  bg-gradient-to-r from-[#6C63FF] to-[#857eff] py-3.5 px-7 font-sans text-sm font-bold uppercase text-white  transition-all hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300   mr-2 mb-2"
  data-ripple-light="true"
>
Let's Connected &#10551;
</button>
<button
onClick={Work}
  class="middle none center rounded-lg  bg-gradient-to-r from-[#e0e0e0] to-[#e0e0e2] py-3.5 px-7 font-sans text-sm font-bold uppercase text-black  transition-all hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300   mr-2 mb-2"
  data-ripple-light="true"
>
Explore Our Work
</button>
<br/><br/>
             <a target="_blank" href="https://www.google.com/maps/place/C9+Production+House/@16.6608505,74.2273656,15z/data=!3m1!4b1!4m6!3m5!1s0x3bc0ff838098a00f:0x45158109d8104ae1!8m2!3d16.6608513!4d74.2458196!16s%2Fg%2F11jtywfs95?entry=ttu"><button   type="button" class="focus:ring-4  focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-4 mb-2"><img src={review} width={240} /></button> 
 </a>                            {/* <button type="button"  class="text-white bg-gradient-to-r from-[#857eff] to-[#6C63FF] hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2">Explore Other Services &#10551;</button>  */}
        </div>
        <div class="lg:mt-0 lg:col-span-5 lg:flex xl:w-160 xl:h-100">
            <img src={hero1} alt="advertising agency" />
        </div>                
    </div>
</section>

<div class="m-6 grid grid-cols-3 gap-1 justify-evenly lg:px-0 xl:px-20">
<div class="col-span-2 "> 
<p class="max-w-2xl mb-2 text-gray-500 md:text-lg lg:text-xl dark:text-gray-400 hidden font-bold lg:block">Workflows can be thought of as a path of operations </p></div> 

</div>
<div class="cards ">
<div class="p-10 grid grid-cols-1  lg:px-2 xl:px-20 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-5">
<div class="flex flex-col rounded-xl bg-white bg-clip-border shadow-card">
  <div class="mx-4 -mt-6 translate-y-0">
    <a href="#" blur-shadow-image="true">
    </a>
  </div>
  <div class="text-secondary flex-1 p-6">
    <a href="#">
      <h4 class="font-bold text-2xl">Visual Design</h4>
    </a>
    <p class="opcacity-60 mb-3">
    Create a visual design that reflects the company's brand and appeals to the target audience.
    </p>
   <img src={a1} class="w-10"/>
  </div>
</div>
<div class="flex flex-col rounded-xl bg-white bg-clip-border shadow-card">
  <div class="mx-4 -mt-6 translate-y-0">
    <a href="#" blur-shadow-image="true">
    </a>
  </div>
  <div class="text-secondary flex-1 p-6">
    <a href="#">
      <h4 class="font-bold text-2xl">Refine the design</h4>
    </a>
    <p class="opcacity-60 mb-3">
    Your goals and is visually appealing. Make any necessary adjustments based on your testing and feedback from others
    </p>
   <img src={a2} class="w-10"/>
  </div>
</div>
<div class="flex flex-col rounded-xl bg-white bg-clip-border shadow-card">
  <div class="mx-4 -mt-6 translate-y-0">
    <a href="#" blur-shadow-image="true">
    </a>
  </div>
  <div class="text-secondary flex-1 p-6">
    <a href="#">
      <h4 class="font-bold text-2xl">Finalize the design</h4>
    </a>
    <p class="opcacity-60 mb-3">
    Once you're satisfied with the design finalize it by saving it And Handover to You.
    </p>
   <img src={a3} class="w-10"/>
  </div>
</div>
</div>

</div>
<div className="Hero2">
<section class="bg-white dark:bg-gray-900 xl:px-20">
    <div class="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-20 xl:gap-20 lg:py-16 lg:grid-cols-12">
    <div class=" lg:mt-0 lg:col-span-5 lg:flex px-6 lg:px-0">
            <img src={hero2}  alt="c9 production advertise" />
        </div>     
        <div class="mr-auto place-self-center lg:col-span-7 px-8">
            <h1 class="max-w-2xl mb-4 text-4xl font-extrabold text-[#6C63FF] tracking-tight leading-none md:text-[29px] xl:text-4xl dark:text-white">Innovative Advertising that Sets Your Brand Apart</h1>
            <p class="max-w-2xl mb-2 font-normal text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">Transform Your Brand's Story with Our Cutting-Edge Advertising Techniques.From Social Media to Outdoor, We Deliver Unique Advertising Solutions for Every Channel.Get Noticed in a Crowded Marketplace - Our Innovative Advertising Techniques Get Results</p>
        </div>
                 
    </div>
</section>
</div> 
<div class="cards">
<section class="bg-white dark:bg-gray-900  xl:px-20">
  <div class="py-8 px-12 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
      <div class="max-w-screen-md mb-2 lg:mb-16">
          <a href="/Services"><h2 class="mb-4 text-4xl tracking-tight font-extrabold text-[#6C63FF] dark:text-white">Our Comprehensive  Services</h2></a>
          <p class="text-gray-500 sm:text-xl dark:text-gray-400">we offer a comprehensive range of services designed to help businesses create a lasting impression on their target audiences</p>
      </div>
      <div class="space-y-8 md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-12 md:space-y-0">
          <div>
              <div class="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 dark:bg-primary-900">
              <img class="w-9 h-9 text-primary-600 lg:w-9 lg:h-9 dark:text-primary-300"  src={ph}/>
              </div>
              <h3 class="mb-2 text-xl font-bold dark:text-white">Photography</h3>
              <p class="text-gray-500 dark:text-gray-400">Photography is an incredible art form that allows us to capture moments in time.</p>
          </div>
          <div>
              <div class="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 dark:bg-primary-900">
              <img class="w-9 h-9 text-primary-600 lg:w-9 lg:h-9 dark:text-primary-300"  src={dm}/>
              </div>
              <h3 class="mb-2 text-xl font-bold dark:text-white">Digital Marketing</h3>
              <p class="text-gray-500 dark:text-gray-400">Transform Your Online Presence and Boost Your Business with Our Digital Marketing Services.</p>
          </div>
          <div>
              <div class="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 dark:bg-primary-900">
              <img class="w-9 h-9 text-primary-600 lg:w-9 lg:h-9 dark:text-primary-300"  src={da}/>
              </div>
              <h3 class="mb-2 text-xl font-bold dark:text-white">Digital Ad</h3>
              <p class="text-gray-500 dark:text-gray-400">Get Noticed Online with Captivating Digital Ads - Drive Traffic and Increase Sales Today!.</p>
          </div>
          <div>
              <div class="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 dark:bg-primary-900">
                  {/* <svg class="w-5 h-5 text-primary-600 lg:w-6 lg:h-6 dark:text-primary-300" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M8.433 7.418c.155-.103.346-.196.567-.267v1.698a2.305 2.305 0 01-.567-.267C8.07 8.34 8 8.114 8 8c0-.114.07-.34.433-.582zM11 12.849v-1.698c.22.071.412.164.567.267.364.243.433.468.433.582 0 .114-.07.34-.433.582a2.305 2.305 0 01-.567.267z"></path><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a1 1 0 10-2 0v.092a4.535 4.535 0 00-1.676.662C6.602 6.234 6 7.009 6 8c0 .99.602 1.765 1.324 2.246.48.32 1.054.545 1.676.662v1.941c-.391-.127-.68-.317-.843-.504a1 1 0 10-1.51 1.31c.562.649 1.413 1.076 2.353 1.253V15a1 1 0 102 0v-.092a4.535 4.535 0 001.676-.662C13.398 13.766 14 12.991 14 12c0-.99-.602-1.765-1.324-2.246A4.535 4.535 0 0011 9.092V7.151c.391.127.68.317.843.504a1 1 0 101.511-1.31c-.563-.649-1.413-1.076-2.354-1.253V5z" clip-rule="evenodd"></path></svg> */}
                  <img class="w-9 h-9 text-primary-600 lg:w-9 lg:h-9 dark:text-primary-300"  src={vi}/>
              </div>
              <h3 class="mb-2 text-xl font-bold dark:text-white">Video Editing</h3>
              <p class="text-gray-500 dark:text-gray-400">Elevate Your Video Content and Impress Your Audience with Our Professional Video Editing Services.</p>
          </div>
          <div>
              <div class="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 dark:bg-primary-900">
              <img class="w-9 h-9 text-primary-600 lg:w-9 lg:h-9 dark:text-primary-300"  src={pd}/>
              </div>
              <h3 class="mb-2 text-xl font-bold dark:text-white">Product Branding</h3>
              <p class="text-gray-500 dark:text-gray-400"> Lift up Your Brand and Stand Out from the Competition with Our Product Branding Services.</p>
          </div>
          <div>
              <div class="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 dark:bg-primary-900">
                  <img class="w-9 h-9 text-primary-600 lg:w-9 lg:h-9 dark:text-primary-300"  src={an}/>
              </div>
              <h3 class="mb-2 text-xl font-bold dark:text-white">Animation</h3>
              <p class="text-gray-500 dark:text-gray-400">Experience the Next Level of Visual Storytelling with Our High-Quality Animation Services.</p>
          </div>
      </div>
  </div>
</section>
</div>
<div class="hero3">
<section class="bg-white dark:bg-gray-900 xl:px-20">
    <div class="grid max-w-screen-xl px-4 py-4 mx-auto lg:gap-20 xl:gap-20 lg:py-16 lg:grid-cols-12">
   
        <div class="mr-auto place-self-center lg:col-span-7 lg:px-0 px-8">
            <h1 class="max-w-2xl mb-4 text-4xl font-extrabold text-[#6C63FF] tracking-tight leading-none md:text-[29px] xl:text-4xl dark:text-white">Your Brand's Potential with Stunning Creative Design</h1>
            <p class="max-w-2xl mb-0 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">The process of creating visual or artistic content that is intended to communicate a message or evoke a certain feeling or emotion. It can be used in a variety of contexts, including advertising, branding, web design, product packaging, and more.</p>
        </div>
        <div class=" lg:mt-0 lg:col-span-5 lg:flex px-6 lg:px-0">
            <img src={h3}  alt="mockup"/>
        </div>        
    </div>
</section>
</div>


</div>

  );
};

export default Home;
