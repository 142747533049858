import React from "react";

const Policy = () => {
  return (

<div class="container flex flex-wrap items-center justify-between mx-auto " >
<div class="grid max-w-screen-xl  lg:px-2 xl:px-24 px-12 sm:px-30 py-8 ">
      
      
<h1 class="max-w-2xl mb-4 text-4xl font-extrabold text-[#6C63FF] tracking-tight leading-none md:text-[29px] xl:text-4xl dark:text-white">Privacy Policy</h1>
          

        <p>

        At C9productionhouse, we take your privacy and the security of your personal information very seriously. This Privacy Policy outlines how we collect, use, and protect your personal information when you use our services or visit our website.

Information We Collect: We may collect personal information from you when you visit our website, sign up for our services, or communicate with us through email or other channels. This may include your name, contact information, and other details related to your business.

How We Use Your Information: We may use your personal information to provide you with our services, communicate with you about your account or other business matters, and send you marketing materials or other relevant information.

How We Protect Your Information: We take appropriate measures to safeguard your personal information, including the use of secure servers and encryption technology. We also limit access to your personal information to authorized personnel and third-party service providers who need access to perform their duties.

Sharing Your Information: We may share your personal information with third-party service providers who help us deliver our services, such as payment processors or hosting providers. We do not share your personal information with third parties for marketing purposes.

Cookies and Other Tracking Technologies: We may use cookies and other tracking technologies to collect information about your use of our website and improve your user experience. You can adjust your browser settings to control the use of cookies and tracking technologies.

Your Rights: You have the right to access and update your personal information, as well as the right to request that we delete your personal information. Please contact us to exercise these rights.

Changes to Our Privacy Policy: We may update this Privacy Policy from time to time to reflect changes in our business operations or applicable laws and regulations. We will notify you of any significant changes 

</p>
                     
    </div>

</div>


  );
};

export default Policy ;