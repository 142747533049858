import React from "react";
import fb from "../Asset/Social/fb.png"
import insta from "../Asset/Social/insta.png"
// import wt from "../Asset/Social/wt.png"
import ink from "../Asset/Social/in.png"
import tw from "../Asset/Social/tw.png"
import yt from "../Asset/Social/yt.png"
import ssl from "../Asset/ssl-certificate.png"
const Footer = () => {
  return (
    

<div class="container pt-10 items-center justify-between mx-auto xl:px-20  ">

<footer class="p-4 bg-white sm:p-6 dark:bg-gray-900 xl:px-16 px-10">
    <div class="md:flex md:justify-between">
        <div class="mb-6 md:mb-0">
            <a href="https://flowbite.com/" class="flex items-center">
            <img src="https://res.cloudinary.com/dcepokpft/image/upload/v1676967655/logo_lffzv3.png" class="h-6 mr-3 sm:h-9" alt="c9 Logo" />
            <img src={ssl} class="w-8"/>
            </a>
            <br>
            </br>
            <p>Elevate Your Brand's Credibility and Awareness<br/> - We Help You Rise Above the Noise</p>
            
        </div>
        <div class="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3">
            <div>
                <h2 class="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">Resources</h2>
                <ul class="text-gray-600 dark:text-gray-400">
                    <li class="mb-4">
                        <a href="/about" class="hover:underline">About Us </a>
                    </li>
                    <li>
                        <a href="/contact" class="hover:underline">Contact Us</a>
                    </li>
                </ul>
            </div>
            <div>
                <h2 class="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">Follow us</h2>
                <ul class="text-gray-600 dark:text-gray-400">
                    <li class="mb-4">
                        <a href="mailto:contact@c9productionhouse.com" class="hover:underline ">Email Me</a>
                    </li>
                    <li>
                        <a href="/works" class="hover:underline">Our Work </a>
                    </li>
                </ul>
            </div>
            <div>
                <h2 class="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">Legal</h2>
                <ul class="text-gray-600 dark:text-gray-400">
                    <li class="mb-4">
                        <a href="/policy" class="hover:underline">Privacy Policy</a>
                    </li>
                    <li>
                        <a href="/Terms" class="hover:underline">Terms &amp; Conditions</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <hr class="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
    <div class="sm:flex sm:items-center sm:justify-between">
        <span class="text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2023 <a href="/" class="hover:underline">OC™</a>. All Rights Reserved.
       
        </span>
        <div class="flex mt-4 space-x-4 sm:justify-center sm:mt-0">
            <a href="https://www.facebook.com/c9productionhouse/" target="_blank" class="text-gray-500 hover:text-gray-900 dark:hover:text-white">
            <img class="w-5 h-5 text-primary-600 lg:w-6 lg:h-6 dark:text-primary-300"  src={fb}/>
            </a>
            <a href="https://www.instagram.com/c9production/" target="_blank" class="text-gray-500 hover:text-gray-900 dark:hover:text-white">
            <img class="w-5 h-5 text-primary-600 lg:w-6 lg:h-6 dark:text-primary-300"  src={insta}/>
            </a>
            {/* <a href="whatsapp://send?text=Hello!&phone=+918657584769" target="_blank" class="text-gray-500 hover:text-gray-900 dark:hover:text-white">
            <img class="w-5 h-5 text-primary-600 lg:w-6 lg:h-6 dark:text-primary-300"  src={wt}/>
            </a> */}
            <a href="https://in.linkedin.com/company/c9-production?trk=public_profile_topcard-current-company" target="_blank" class="text-gray-500 hover:text-gray-900 dark:hover:text-white">
            <img class="w-5 h-5 text-primary-600 lg:w-6 lg:h-6 dark:text-primary-300"  src={ink}/>
            </a>
            <a href="https://mobile.twitter.com/c9_house" class="text-gray-500 hover:text-gray-900 dark:hover:text-white">
            <img class="w-5 h-5 text-primary-600 lg:w-6 lg:h-6 dark:text-primary-300"  src={tw}/>
            </a>
            <a href="https://www.youtube.com/@c9productionhouse468" class="text-gray-500 hover:text-gray-900 dark:hover:text-white">
            <img class="w-5 h-5 text-primary-600 lg:w-6 lg:h-6 dark:text-primary-300"  src={yt}/>
            </a>
        </div>
    </div>
</footer>


</div>

  );
};

export default Footer;
