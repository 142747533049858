import React from "react";
import w1 from "../Asset/Work_Sample/w1.jpg"
import w2 from "../Asset/Work_Sample/w2.jpg"
import w3 from "../Asset/Work_Sample/w3.jpg"
import w4 from "../Asset/Work_Sample/w4.jpg"
import w5 from "../Asset/Work_Sample/w5.jpg"
import w6 from "../Asset/Work_Sample/w6.jpg"

import under from "../Asset/Work_Sample/under.jpg"
const Works = () => {
                    
  return (
    

<div class="container  items-center justify-between mx-auto ">
  <div class="px-8 pt-14 xl:px-32">
    <img src={under} name="c9 production house works" alt="work"/>
  </div>

{/* <section class="overflow-hidden text-gray-700">
  <div class="container px-5 py-2 mx-auto lg:pt-24 lg:px-32">
    <div class="flex flex-wrap -m-1 md:-m-2">
      <div class="flex flex-wrap w-1/2">
      <div class="w-1/2 p-1 md:p-2">
          <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
            src={w6}/>
        </div>
        <div class="w-1/2 p-1 md:p-2">
          <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
            src={w4}/>
        </div>
        <div class="w-full p-1 md:p-2">
          <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
            src={w2}/>
        </div>
      </div>
      <div class="flex flex-wrap w-1/2">
        <div class="w-full p-1 md:p-2">
          <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
            src={w1}/>
        </div>
        <div class="w-1/2 p-1 md:p-2">
          <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
            src={w5}/>
        </div>
        <div class="w-1/2 p-1 md:p-2">
          <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
            src={w4}/>
        </div>
      </div> 
      <div class="flex flex-wrap w-1/1">
        <div class="w-full ">
          <img alt="Photography" class="block object-cover object-center w-full rounded-lg"
            src={p}/>
        </div> 
        <div class="w-1/2 p-1 md:p-2">
          <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
            src={w6}/>
        </div>
        <div class="w-1/2 p-1 md:p-2">
         <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
            src={w3}/>
        </div>
      </div>
    </div>
  </div>
</section> */}
    

</div>

  );
};

export default Works;
