import React from "react";
import Navbar from "./Componants/Navbar";
import Home from "./Componants/Home";
import Services from "./Componants/Services";
import About from "./Componants/About";
import Page404 from "./Componants/Page404";
import Works from "./Componants/Works";
import Footer from "./Componants/Footer";
import Policy from "./Componants/Policy";
import Pagewp from "./Componants/Pagewp";
import Terms from "./Componants/Terms";
import Photography  from "./Componants/Services/photography";
import Video_editing  from "./Componants/Services/videoe";
import Digital_Marketing  from "./Componants/Services/dm";
import Animation  from "./Componants/Services/animation";
import Branding  from "./Componants/Services/branding";
import Digital_AD  from "./Componants/Services/digitalad";
import Contact from "./Componants/Contact";
import { BrowserRouter,Route, Routes } from "react-router-dom";


function App() {

  return (
    <BrowserRouter>
      <Navbar /> 
      <Routes>
        <Route path="*" element={<Page404 />} />
        <Route path="/" element={<Home />} />
        <Route path="/Policy" element={<Policy />} />
        <Route path="/wp-admin" element={<Pagewp />} />
        <Route path="/wp-login" element={<Pagewp />} />
        <Route path="/Terms" element={<Terms />} />
        <Route path="/Services" element={<Services />} />{}
        <Route path="/About" element={<About />} />{}
        <Route path="/Contact" element={<Contact />} />{}
        <Route path="/Works" element={<Works />} />{}
        <Route path="/Services/photography" element={<Photography />} />
        <Route path="/Services/Video_Editing" element={<Video_editing/>} />
        <Route path="/Services/Digital_Marketing" element={<Digital_Marketing/>} />
        <Route path="/Services/Animation" element={<Animation />} />
        <Route path="/Services/Digital_Ad" element={<Digital_AD />} />
        <Route path="/Services/Branding" element={<Branding />} />
      </Routes>
      
      <Footer /> 
    
    </BrowserRouter>
    
  );
}

export default App;
