import React from "react";
import dm from "../Asset/Services_icon/dm.png"
import da from "../Asset/Services_icon/da.png"
import ph from "../Asset/Services_icon/ph.png"
import pd from "../Asset/Services_icon/pd.png"
import an from "../Asset/Services_icon/otaku.png"
import vi from "../Asset/Services_icon/video.png"
const Services = () => {
  return (

<div class="container flex flex-wrap items-center justify-between mx-auto " >
<div class="grid max-w-screen-xl xl:pr-32 lg:px-2 xl:px-24 px-12 sm:px-30 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
        <div class="mr-auto place-self-center lg:col-span-7">
      
<h1 class="max-w-2xl mb-4 text-4xl font-extrabold text-[#6C63FF] tracking-tight leading-none md:text-[29px] xl:text-4xl dark:text-white">Services that will help
Your Buiseness</h1>
            <p class="max-w-2xl mb-6 font-normal text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">At <span class="text-[#6C63FF]">C9</span>productionhouse, we offer a wide range of advertising services to help our clients reach their target audiences and achieve their business goals. Some of our services.</p>
          
        </div>
                     
    </div>
<div class="xl:px-24">

  <div class="p-10 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-5">
  <div class="max-w-sm p-6 bg-white border  border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
   
    <a href="/Services/photography">
    <img src={ph} class="hero container max-w-screen-lg mx-auto pb-10 flex justify-center  w-32 h-22" />
        <h5 class="mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">Photography</h5>
        <p class="mb-3 font-normal text-gray-500 dark:text-gray-400">Photography is an incredible art form that allows us to capture moments with our Services:</p>
    </a>
    

</div>
<div class="max-w-sm p-6 bg-white border  border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
   
    <a href="/Services/Digital_Marketing">
    
    <img src={dm} class="hero container max-w-screen-lg mx-auto pb-10 flex justify-center  w-32 h-22" />
        <h5 class="mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">Digital Marketing</h5>
        <p class="mb-3 font-normal text-gray-500 dark:text-gray-400">Transform Your Online Presence and Boost Your Business with Our Digital Marketing Services:</p>
    </a>
    

</div>
<div class="max-w-sm p-6 bg-white border  border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
   
    <a href="/Services/Digital_Ad">
    <img src={da} class="hero container max-w-screen-lg mx-auto pb-10 flex justify-center  w-32 h-22" />
        <h5 class="mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">Digital Ad</h5>
        <p class="mb-3 font-normal text-gray-500 dark:text-gray-400">Get Noticed Online with Our Captivating Digital Ads Services:</p>
    </a>
    

</div>
  </div>
  <div class="p-10 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-5">
  <div class="max-w-sm p-6 bg-white border  border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
   
    <a href="/Services/Video_Editing">
    <img src={vi} class="hero container max-w-screen-lg mx-auto pb-10 flex justify-center  w-32 h-22" />
        <h5 class="mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">Video Editing</h5>
        <p class="mb-3 font-normal text-gray-500 dark:text-gray-400"> Bring Your Vision to Life with Professional Video Editing Services:</p>
    </a>
    

</div>
<div class="max-w-sm p-6 bg-white border  border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
   
    <a href="/Services/Branding">
    <img src={pd} class="hero container max-w-screen-lg mx-auto pb-10 flex justify-center  w-32 h-22" />
        <h5 class="mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">Product Branding </h5>
        <p class="mb-3 font-normal text-gray-500 dark:text-gray-400">Lift up Your Brand and Stand Out from the Competition with Our Product Branding Services:</p>
    </a>
    

</div>
<div class="max-w-sm p-6 bg-white border  border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
   
    <a href="/Services/Animation">
    <img src={an} class="hero container max-w-screen-lg mx-auto pb-10 flex justify-center  w-32 h-22" />
        <h5 class="mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">Animation</h5>
        <p class="mb-3 font-normal text-gray-500 dark:text-gray-400">Experience the Next Level of Visual Storytelling with Our High-Quality Animation Services:</p>
    </a>
    

</div>

  </div>
</div>

</div>


  );
};

export default Services ;