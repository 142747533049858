import React from "react";
import hero1 from "../Asset/hero13.svg"
const Home = () => {
  return (
<div class="container  items-center justify-between mx-auto ">
<section class="bg-white dark:bg-gray-900 xl:px-20">
    <div class="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-20 xl:gap-20 lg:py-16 lg:grid-cols-12">
    <div class=" lg:mt-0 lg:col-span-5 lg:py-16 px-6 lg:px-0">
            <img src={hero1}  alt="mockup"/>
        </div>     
        <div class="mr-auto place-self-center lg:col-span-7 px-8">
            <h1 class="max-w-2xl mb-4 text-4xl font-extrabold text-[#6C63FF] tracking-tight leading-none md:text-[29px] xl:text-4xl dark:text-white">About Us</h1>
            <p class="text-gray-500 xl:font-normal font-bold  dark:text-white">Welcome to our cutting-edge advertising agency, where creativity meets strategy to deliver impactful and measurable results for our clients. We specialize in developing innovative marketing campaigns that capture attention, engage audiences, and drive growth for businesses of all sizes. With our team of experienced professionals, we bring a wealth of knowledge and expertise to every project, ensuring that we create unique solutions tailored to meet our clients' specific needs. Whether you're looking to build brand awareness, increase leads and sales, or simply enhance your online presence, we have the tools, skills, and passion to make it happen. Let's work together to take your business to the next level.</p>
            {/* <p class="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">Welcome to our cutting-edge advertising agency, where creativity meets strategy to deliver impactful and measurable results for our clients. We specialize in developing innovative marketing campaigns that capture attention, engage audiences, and drive growth for businesses of all sizes. With our team of experienced professionals, we bring a wealth of knowledge and expertise to every project, ensuring that we create unique solutions tailored to meet our clients' specific needs. Whether you're looking to build brand awareness, increase leads and sales, or simply enhance your online presence, we have the tools, skills, and passion to make it happen. Let's work together to take your business to the next level.</p> */}
        </div>
                 
    </div>
</section>
</div>

  );
};

export default Home;
