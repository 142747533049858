import React from "react";
import { useNavigate } from "react-router-dom";
import photo from './../../Asset/Services/ani.gif'


const Animation = () => {
  let navigate = useNavigate(); 
  const Contact = () =>{ 
    let path = `/Contact`; 
    navigate(path);
  }
  const Services = () =>{ 
    let path = `/Services`; 
    navigate(path);
  }
  return (
<div class="container  items-center justify-between mx-auto ">
<section class="bg-white dark:bg-gray-900 xl:px-20">
    <div class="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-20 xl:gap-20 lg:py-16 lg:grid-cols-12">
    <div class=" lg:mt-0 lg:col-span-5 lg:flex px-6 lg:px-0">
    <img src={photo} alt="loading..." />
        </div>     
        <div class="mr-auto place-self-center lg:col-span-7 px-8">
        <h1 class="max-w-2xl mb-4 text-4xl font-extrabold text-[#6C63FF] tracking-tight leading-none md:text-[29px] xl:text-4xl dark:text-white">We Provide High-Quality Animation </h1>
        <h1 class="my-2 text-gray-800 font-bold text-2xl">
        Experience the Next Level of Visual Storytelling with Our High-Quality Animation Services

                            </h1>
                            <p class="my-2 text-gray-800">The most effective way to bring the story to life. By leveraging advanced  animation techniques, visual effects, and sound design,animation services can create highly engaging and impactful animations that can leave a lasting impression on the viewer.</p>
                            <br/>
                            <button  onClick={Contact} type="button" class="text-white bg-gradient-to-br from-[#6C63FF] to-[#857eff] hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2">Let's Connected</button>
                            <button type="button" onClick={Services} class="text-white bg-gradient-to-r from-[#857eff] to-[#6C63FF] hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2">Explore Other Services &#10551;</button>
        </div>
                 
    </div>
</section>
</div>

  );
};

export default  Animation;
